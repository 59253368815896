import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { VideoFrame } from "../../styles/components/VideoFrame";
import OptimizedImage from "../../utils/optimizedImage";
import {
  VideoSectionWrapper,
  ImageWrapper,
  ThumbnailWrapper,
} from "./styles/VideoSectionWebinar.styled";
import {
  fetchCustomVideoMetadata,
  fetchVimeoData,
  fetchYoutubeData,
  formatDuration,
  getCustomVideoDuration,
  validatePlay,
} from "../../utils/validateVideo";
import onKeyDown from "../../utils/onKeyDown";
import { Helmet } from "react-helmet";

// Lazy load ModalVideo to optimize performance
const LazyModalVideo = lazy(() => import("react-modal-video"));

export const VideoSectionWebinar = ({ component }) => {
  const [play, setPlay] = useState(false);
  const [videoId, setVideo] = useState(null);
  const [channel, setChannel] = useState("");
  const [duration, setDuration] = useState("");
  const [uploadDate, setUploadDate] = useState("");

  // Memoized function to avoid re-renders
  const playVideo = useCallback(
    (video) => validatePlay(video, setChannel, setVideo, setPlay),
    []
  );

  // Fetch metadata only when videoId changes
  useEffect(() => {
    if (!videoId) return;

    const fetchVideoMetadata = async () => {
      let videoData = {};
      if (channel === "youtube") {
        videoData = await fetchYoutubeData(videoId);
      } else if (channel === "vimeo") {
        videoData = await fetchVimeoData(videoId);
      } else if (channel === "custom") {
        videoData.duration = await getCustomVideoDuration(videoId);
        videoData.uploadDate = await fetchCustomVideoMetadata(videoId);
      }

      setDuration(formatDuration(videoData?.duration || 0));
      setUploadDate(videoData?.uploadDate || "2025-01-01T12:00:00Z");
    };

    fetchVideoMetadata();
  }, [videoId, channel]);

  // Structured data for SEO
  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "embedUrl": component?.videoFile?.file?.url
      ? `https:${component.videoFile.file.url}`
      : component?.videoUrl,
    "thumbnailUrl":
      component?.thumbnail?.file?.url ||
      component?.thumbnail?.gatsbyImageData?.images?.sources[0]?.srcSet ||
      "Video Thumbnail",
    "name": component?.title || "Video Title",
    "description": component?.description || "Video Description",
    "duration": duration,
    "uploadDate": uploadDate,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <VideoSectionWrapper>
        <div className="content">
          <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
            <ImageWrapper>
              <ThumbnailWrapper>
                <figure className="blog-image-wrapper">
                  {component?.thumbnail ? (
                    <OptimizedImage
                      image={component?.thumbnail?.gatsbyImageData}
                      src={component?.thumbnail?.file?.url}
                      alt={
                        component?.thumbnail?.description ||
                        component?.thumbnail?.title ||
                        "thumbnail"
                      }
                    />
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span
                    onClick={() => playVideo(component)}
                    role="button"
                    aria-label="play"
                    tabIndex={0}
                    onKeyDown={(e) => onKeyDown(e, () => playVideo(component), "Enter")}
                  >
                    <img src="/icons/video-play.svg" alt="watch video button" />
                  </span>
                </div>
              </ThumbnailWrapper>
            </ImageWrapper>
          </div>
        </div>
        {typeof window !== "undefined" && play && (
          <VideoFrame>
            <Suspense fallback={<div>Loading Video...</div>}>
              <LazyModalVideo
                channel={channel}
                isOpen={play}
                videoId={videoId}
                url={channel === "custom" ? videoId : undefined}
                onClose={() => setPlay(false)}
                autoplay
              />
            </Suspense>
          </VideoFrame>
        )}
      </VideoSectionWrapper>
    </>
  );
};
